import service from "../request";
const baseUrl = window.globalUrl.HOME_API;
//查询作业季
export const selectSeasonByAreaCode = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njjg/selectSeasonByAreaCode`,
    method: "get",
    params: params,
  });
};

//查询作业环节
export const selectLinkByAreaCode = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njjg/selectLinkByAreaCode`,
    method: "get",
    params: params,
  });
};

//查询作业环节
export const selectHomeworkSituationByAreaCode = (params) => {
  return service({
    url: `${baseUrl}/trade-website/njjg/selectHomeworkSituationByAreaCode`,
    method: "get",
    params,
  });
};

//查询农机轨迹数据
export const machineTrajectoryData = (params) => {
  return service({
    url: `${baseUrl}/trade-website/ldtp-machine-user-history/machineTrajectoryData`,
    method: "post",
    data: params,
  });
};

//查询农机轨迹数据
export const getCropContent = (params) => {
  return service({
    url: `${baseUrl}/trade-website/order/getCropContent?id=100900790780036864`,
    method: "get",
  });
};
