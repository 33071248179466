<template>
  <div>
    <el-row
      style="
        background-color: blue;
        height: 50px;
        display: flex;
        align-items: center;
      "
    >
      <el-col :span="4" :offset="1">
        <div
          style="font-weight: bold; font-size: 20px; height: 100%; color: white"
        >
          社会化服务监测
        </div>
      </el-col>
      <el-col :span="4" :offset="15">
        <el-button type="text" icon="el-icon-data-board" @click="dataAnalysis"
          >数据分析</el-button
        >
      </el-col>
    </el-row>
    <div style="margin: 20px 20px 0 20px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-在线农机
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ zxnj }} 台
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-今日作业面积
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ jrzymj }} 亩
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-本作业季累计作业面积
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ bzyjljzymj }} 亩
            </div>
          </el-card>
        </el-col>

        <el-col :span="6">
          <el-card>
            <div>
              <div style="font-weight: bold; font-size: 20px">
                全县-总农机数
              </div>
            </div>
            <div
              style="
                font-weight: bold;
                font-size: 20px;
                color: blue;
                margin-top: 20px;
              "
            >
              {{ znjs }} 台
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row style="height: 50px; align-items: center">
        <el-card style="margin: 20px 20px 0 20px">
          <el-row :gutter="20">
            <el-col :span="4">
              <div style="font-weight: bold; font-size: 20px; height: 100%">
                农机作业轨迹
              </div>
            </el-col>
            <el-form
              :model="form"
              label-position="left"
              label-width="80px"
              inline
              ref="form"
            >
              <el-col :span="4">
                <el-form-item>
                  <el-date-picker
                    size="mini"
                    v-model="form.year"
                    type="year"
                    format="yyyy"
                    value-format="yyyy"
                    placeholder="选择年"
                    @change="handleTimeChange"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-select
                    size="mini"
                    v-model="form.seasonId"
                    placeholder="请选择作业季"
                  >
                    <el-option
                      v-for="item in seasonDataOPtions"
                      :key="item.id"
                      :label="item.workSeasonName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-select
                    size="mini"
                    v-model="form.orderContent"
                    placeholder="请选择农事环节"
                  >
                    <el-option
                      v-for="item in linkOptions"
                      :key="item.label"
                      :label="item.label"
                      :value="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item>
                  <el-select
                    size="mini"
                    v-model="form.cropCode"
                    placeholder="请选择作物"
                  >
                    <el-option
                      v-for="item in zuOptions"
                      :key="item.categoryName"
                      :label="item.categoryName"
                      :value="item.categoryName"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item>
                  <el-input
                    size="mini"
                    v-model="form.companyName"
                    placeholder="请输入合作社名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                  <el-button
                class="query-btn"
                type="primary"
                @click="machineTrajectoryData"
                >搜索</el-button
              >
              </el-col>

            </el-form>

            <!-- <el-col :span="4">
              <el-button
                size="mini"
                icon="el-icon-zoom-in"
                type="primary"
                @click="magnify"
                >放大</el-button
              >
              <el-button
                size="mini"
                icon="el-icon-zoom-out"
                type="primary"
                @click="reduce"
                >缩小</el-button
              >
            </el-col> -->
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col>
            <div class="map-container">
              <div ref="mapChart" style="width: 100%; height: 80vh">
                <div v-if="loading" class="loading-spinner">
                  <div class="loader"></div>
                </div>
              </div>
            </div>
            </el-col>
          </el-row>
        </el-card>
      </el-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {
  selectSeasonByAreaCode,
  selectLinkByAreaCode,
  selectHomeworkSituationByAreaCode,
  machineTrajectoryData,
} from "@/api/njjg";
import { get } from "lodash";
import * as echarts from "echarts";
require("echarts/extension/bmap/bmap"); // 引入百度地图扩展

export default {
  data: () => ({
    yearData: "",
    season: "",
    link: "",
    zu: "",
    hzs: "",
    seasonDataOPtions: [],
    linkOptions: [],
    zuOptions: [],
    //上方数据
    zxnj: "",
    jrzymj: "",
    bzyjljzymj: "",
    znjs: "",
    trajectoryData: [], // 从API获取轨迹数据
    mapCenter: [116.404, 39.915], // 初始中心点
    mapZoom: 15,
    form: {
      year: "",
      seasonId: "",
      orderContent: "",
      cropCode: "",
      companyName: ""
    },
    currentBounds: null,
    chart: null,
    loading: false,
    bmap: null,
    center: null,
  }),

  created() {
    this.getLinkOptions();
    this.getData();
  },

  mounted() {
    this.machineTrajectoryData();
    // this.initMap();
    window.addEventListener('resize', this.handleResize);


    //隐藏头部和底部
    let header = document.querySelector(".header");
    let noShow = document.querySelector(".noShow");
    // let homeManage = document.querySelector(".home-manage");
    let footer = document.querySelector(".footer");
    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
    // homeManage.style = "padding-top:0;width:100vw;";

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {

    handleResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },

    //列表数据
    async machineTrajectoryData() {
      this.loading = true;
        let params = {
          ...this.form
        }
        let res = await machineTrajectoryData(params)
        if (res.code == 0) {
          this.trajectoryData = res.data;
          this.loading = false;
        } else {
          this.loading = false;
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
        this.updateCenter();
        // this.initMap();
    },

    dataAnalysis() {
      window.open(window.location.origin + `#/sjfx`, "_blank", 'noopener');
    },
    getData() {
       //隐藏头部和底部
    let header = document.querySelector(".header");
    console.log('header',header)
    let noShow = document.querySelector(".noShow");

    let footer = document.querySelector(".footer");
    header.style = "display:none";
    footer.style = "display:none";
    noShow.style = "display:none";
      let seasonDate = new Date();
      selectHomeworkSituationByAreaCode({
        type: "1",
        seasonDate: moment(seasonDate).format("YYYY-MM-DD HH:mm:ss"),
      }).then((res) => {
        this.zxnj = res.data.zxnj;
        this.jrzymj = res.data.jrzymj;
        this.bzyjljzymj = res.data.bzyjljzymj;
        this.znjs = res.data.znjs;
      });
    },
    handleTimeChange(event) {
      const yearData = event;
      this.getSeasonDataOPtions(yearData);
    },
    //获取作业季
    async getSeasonDataOPtions(yearData) {
      if (yearData == "" || yearData == null) {
        yearData = "";
      }
      let params = {
        year: yearData
      }
      selectSeasonByAreaCode(params).then((res) => {
        this.seasonDataOPtions = res.data;
      });
    },
    //获取作业环节
    async getLinkOptions() {
      selectLinkByAreaCode().then((res) => {
        if (res.code == 0) {
          this.linkOptions = res.data.jobsType;
          this.zuOptions = res.data.categoryPrices;
        }
      });
    },
    magnify() {},
    reduce() {},


    // 数据清洗和转换
    processTrackData(rawData) {
      return rawData.flatMap(track =>
        track.points.map(point => ({
          machineType: track.machineType,
          carNumber: track.carNumber,
          driverName: track.driverName,
          workTime: track.workTime,
          workArea: track.workArea,
          companyName: track.companyName,
          coords: this.removeDuplicatePoints(point.coords),
          lineStyle: {
            color: "#00BFFF",
            width: 1,
            opacity: 0.7,
            curveness: 0, // 路径弯曲度
          },
        }))
      )
    },

    //获取最新的点
    getLatestPoints(rawData) {
      console.log('rawData',rawData)
      return rawData.flatMap(track => {
        const lastPoints = track.points[track.points.length - 1]
        // console.log('lastPoints',lastPoints)
        if (lastPoints != null) {
          const lastPoint = lastPoints.coords[lastPoints.coords.length - 1]
          return {
            lng: lastPoint[0],
            lat: lastPoint[1],
            data: {
            }
          }
        }

      })
        // track.points.map(segment => {
        //   const lastPoint = segment.coords[segment.coords.length - 1]
        //   console.log(lastPoint)
        //   return {
        //     lng: lastPoint[0],
        //     lat: lastPoint[1],
        //     data: {
        //       id: 1
        //     }
        //   }
        // })
      // )
    },

    // 去除连续重复点
    removeDuplicatePoints(points) {
      return points.filter((point, index) => {
        if (index === 0) return true;
        const prev = points[index - 1];
        return !(point[0] === prev[0] && point[1] === prev[1]);
      });
    },
    // 计算轨迹边界中心点
    calculateTrackCenter(tracks) {
      let minLng = Infinity,
        maxLng = -Infinity,
        minLat = Infinity,
        maxLat = -Infinity;

      // 遍历所有轨迹点
      tracks.forEach((track) => {
        track.points.forEach((point) => {
          point.coords.forEach((coord) => {
            const [lng, lat] = coord;
            minLng = Math.min(minLng, lng);
            maxLng = Math.max(maxLng, lng);
            minLat = Math.min(minLat, lat);
            maxLat = Math.max(maxLat, lat);
          });

        });
      });

      // 计算中心点
      const centerLng = (minLng + maxLng) / 2;
      const centerLat = (minLat + maxLat) / 2;

      // 计算推荐缩放级别（经验公式）
      const lngDiff = maxLng - minLng;
      const latDiff = maxLat - minLat;
      const zoom = Math.floor(
        9 - Math.log(Math.max(lngDiff, latDiff * 1.5)) / Math.LN2
      );

      return {
        center: [centerLng, centerLat],
        zoom: Math.min(17, Math.max(14, zoom)),
      };
    },

    // 自动居中方法
    autoCenter() {
      const { center, zoom } = this.calculateTrackCenter(this.trajectoryData);
      this.mapCenter = center;
      this.mapZoom = zoom;
      // 更新地图视图
      const chart = echarts.getInstanceByDom(this.$refs.mapChart);
      chart.setOption({
        bmap: {
          center: this.mapCenter,
          zoom: this.mapZoom,
        },
      });
    },

    updateCenter() {
      var myGeo = new BMap.Geocoder();
        // 将地址解析结果显示在地图上，并调整地图视野
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        console.log(identityObj)
        const self = this;
        let newCenter = myGeo.getPoint(identityObj.content.areaName, (point) => {
            if (point) {
                console.log('经度:', point.lng);
                console.log('经纬度:', [point.lng, point.lat]);
                self.mapCenter = [point.lng, point.lat];
                self.center = point;
                // return [point.lng, point.lat];
                this.initMap() ;
            } else {
                console.error('未找到该地点的坐标');
                return null;
            }
        });
        // console.log('mapcenddd',self.mapCenter)
        // console.log('centerter',this.center);
        return newCenter;
        // 更新地图视图
        // const chart = echarts.getInstanceByDom(this.$refs.mapChart);
        //   chart.setOption({
        //     bmap: {
        //       center: self.mapCenter,
        //       zoom: 15,
        //     },
        //   });
          // return self.mapCenter;
    },
    // 设置地图事件监听
    // setupMapEvent() {
    //   const bmap = this.chart.getModel().getComponent('bmap').getBMap();
    //   bmap.addEventListener('moveend', this.handleMapMove);
    //   bmap.addEventListener('zoomend', this.handleMapMove);
    //   bmap.enableScrollWheelZoom(true);
    //   this.updateMapBounds(bmap);
    // },

    // 处理地图移动事件（带防抖）
    // handleMapMove: _.debounce(function() {
    //   const bmap = this.chart.getModel().getComponent('bmap').getBMap();
    //   this.updateMapBounds(bmap);
    //   this.fetchTrajectoryData();
    // }, 500),

    // 更新当前地图边界
    updateMapBounds(bmap) {
      const bounds = bmap.getBounds();
      this.currentBounds = {
        ne: bounds.getNorthEast(),
        sw: bounds.getSouthWest()
      };
      // console.log('当前地图边界:', this.currentBounds);
    },

    // 使用百度API批量转换
    // convertCoordinates(points) {
    //   return new Promise((resolve) => {
    //     const convertor = new BMap.Convertor()
    //     convertor.translate(
    //       points.map(p => new BMap.Point(p, p)),
    //       1,  // 输入坐标系类型：WGS84
    //       5,  // 输出坐标系类型：百度坐标系
    //       res => {
    //         if (res.status === 0) {
    //           resolve(res.points.map(p => [p.lng, p.lat]))
    //         }
    //       }
    //     )
    //   })
    // },


    initMap() {
      const chartDom = this.$refs.mapChart;
      this.chart = echarts.init(chartDom);

      // 首次加载自动居中
      // if (this.trajectoryData.length > 0) {
      //   this.autoCenter();
      // }
      // else {
      //   const centerPoint = this.updateCenter();
      //   console.log('centerPoint',centerPoint);
      //   this.mapCenter=centerPoint;

      // }

      console.log('mapcenter',this.mapCenter)

      this.chart.setOption({
        bmap: {
          center: this.mapCenter, // 初始中心点
          zoom: this.mapZoom,
          roam: true, // 允许缩放和拖拽
          mapStyle: {
            styleJson: [
              {
                featureType: "road",
                elementType: "geometry",
                stylers: {
                  visibility: "on",
                  color: "#4a4a4a",
                  weight : 2
                },
              },
              {
                "featureType": "green",
                "elementType": "geometry",
                "stylers": {"color": "#1a3a2a"}
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": {
                  "color": "#ff6600",
                  "weight": 3
                }
              },
              {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": {
                  "fontFamily": "Microsoft YaHei", // 使用更清晰的字体
                  "fontSize": 14
                }
              }
            ],
          },
        },
        series: [
          {
            type: "lines",
            coordinateSystem: "bmap",
            data: this.processTrackData(this.trajectoryData),
            polyline: true,
            large: true, // 启用大数据模式
            largeThreshold: 2000, // 数据量>2000时启用优化
            progressiveChunkMode: "mod", // 分片渲染
            blurSize: 2, // GPU加速模糊
            layerId: "track", // 独立渲染层
            lineStyle: {
              color: "#55a8ff",
              width: 1,
              opacity: 0.5,
            },
            effect: {
              show: false,
            },
          },
          // {
          //   type: 'scatter',
          //   coordinateSystem: 'bmap',
          //   large: true,
          //   largeThreshold: 2000,
          //   symbol: 'image://https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png',
          //   symbolSize: 100,
          //   data: this.getLatestPoints(this.trajectoryData),
          //   itemStyle: {
          //     color: '#FF0000'
          //   },
          //   emphasis: { // 高亮状态
          //     scale: 1.2
          //   },
          //   label: {
          //     show: true,
          //     formatter: '{b}',
          //     position: 'top',
          //     color: '#333',
          //     fontSize: 14
          //   }
          // }
        ],
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            const points = params.data.coords;
            return `
              <div style="padding:5px">
                <div>农机类型：${params.data.machineType}</div>
                <div>车牌号：${params.data.carNumber}</div>
                <div>驾驶员：${params.data.driverName}</div>
                <div>本次作业时长：${params.data.workTime}</div>
                <div>服务对象：${params.data.companyName}</div>
              </div>
            `;
          },
        },
      });

      // 关联百度地图实例
      this.bmap = this.chart.getModel().getComponent("bmap").getBMap();
      this.bmap.setMapType(BMAP_HYBRID_MAP);
      this.bmap.setMaxZoom(17.4);

      // 添加地图控件
      this.bmap.addControl(new BMap.NavigationControl());

      // 轨迹点击事件
      // this.chart.on("click", { seriesIndex: 0 }, (params) => {
      //   const track = this.multiTrackData.find(
      //     (t) => t.id === params.dataIndex + 1
      //   );
      // });
      // this.setupMapEvent();
    },
  },
};
</script>
<style scoped>
.map-container {
  /* margin: 10px; */
  border: 1px solid #eee;
  border-radius: 8px;
  overflow: hidden;
  background: #1a1a1a; /* 深色背景增强对比度 */
}
.loading-spinner {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* 背景半透明 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.loader {
  border: 4px solid rgba(0, 0, 0, 0.1); /* 边框颜色 */
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f; /* 转圈颜色 */
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
